import React, { useState, useEffect } from "react";
import { Modal, Box, Typography, IconButton, Skeleton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import WelcomeData from '../Assets/JSON/MV.json';

const WelcomeModal = ({ open, onClose, onImageLoad }) => {
  const [imageLoaded, setImageLoaded] = useState(false);
  const Welcomedata = WelcomeData.HomePage_Details.Notification_Board;

  useEffect(() => {
    if (imageLoaded && onImageLoad) {
        onImageLoad();
    }
  }, [imageLoaded, onImageLoad]);
  return (
    <Modal
      open={open}
      onClose={onClose}
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Box
        sx={{
          display: "flex",
          bgcolor: "white",
          outline: "none",
          borderRadius: 4,
          maxWidth: { xs: "90vw", sm: "70vw", md: "50vw" },
          width: "100%",
          p: 3,
          position: "relative",
          overflow: "auto",
          maxHeight: "100vh",
        }}
      >
        <Box
          sx={{
            flex: 1,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            textAlign: "center",
          }}
        >
          <IconButton
            onClick={onClose}
            sx={{
              position: "absolute",
              top: 8,
              right: 8,
              color: "rgb(20,24,75)",
            }}
          >
            <CloseIcon />
          </IconButton>
          <Typography
            variant="h5"
            id="welcome-modal-title"
            sx={{ mb: 3, fontWeight: 'bold', color: 'rgb(20,24,75)' }}
          >
            {Welcomedata.title}
          </Typography>
          <Typography
            variant="body2"
            id="welcome-modal-description"
            sx={{
              fontSize: '0.9rem',
              lineHeight: '1.6',
              textAlign: 'justify',
              color: "rgb(20,24,75)"
            }}
            className="container py-2"
          >
            {Welcomedata.description}
          </Typography>
          <div style={{ width: "50%", height: "auto", marginBottom: "20px" }}>
            {!imageLoaded && (
              <Skeleton variant="rectangular" width="100%" height={"20rem"} sx={{ borderRadius: '12px' }} />
            )}
            <LazyLoadImage
              src={Welcomedata.image}
              effect="blur"
              style={{ maxWidth: '100%', borderRadius: '12px', boxShadow: "3px 0px 5px rgba(0, 0, 0, 0.3)"  }}
              alt="Welcome Modal Image"
              afterLoad={() => setImageLoaded(true)}  // Set the imageLoaded state
            />
          </div>
        </Box>
      </Box>
    </Modal>
  );
};

export default WelcomeModal;
