import React from "react";
import "./Carosel.css";
import Slider from "react-slick";

const Image_Carosel = (props) => {
  const { image_data, imageStyle } = props;
  const defaultStyles = {
    width: "100%",
    height: "390px",
    objectFit: "cover",
    borderRadius: "8px",
  };

  return (
    <div className="Image_carosel">
      <Slider ref={(slider) => (slider)} arrows={false} autoplay={true} dots={true}>
        {image_data &&
          image_data.map((items, index) => {
            return (
              <div key={index}>
                <img
                  src={
                    items === ""
                      ? "https://dbraeiassetmanagement.blob.core.windows.net/dbraimt/prod/home/Placeholders/Placeholder_Image.jpg"
                      : items
                  }
                  alt="events data"
                  style={imageStyle ? { ...defaultStyles, ...imageStyle } : defaultStyles} // Merge styles
                  onError={(e) =>
                    (e.target.src =
                      "https://dbraeiassetmanagement.blob.core.windows.net/dbraimt/prod/home/Placeholders/Placeholder_Image.jpg")
                  }
                />
              </div>
            );
          })}
      </Slider>
    </div>
  );
};

export default Image_Carosel;
