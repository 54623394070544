import React, { useState } from "react";
import "../../Styles/Study_with_us.css";
import { Grid, Typography, Button, Card, Divider } from "@mui/material";
import NoticeCarosel from "../../Component/Carosel/Notice_Carosel";
import Study_with_us_Htext from "../../Assets/Images/Study_with_us_Htext.png";

const Study_with_us = () => {
  const Study_with = {
    SU: {
      title: "Study With Us",
      description:
        "Dr.B.R. Ambedkar Law College in Hyderabad is a renowned institution that has been empowering students with legal education for decades. With a focus on social justice and equality, the college has produced numerous accomplished legal professionals who have  made a signiﬁcant impact on society. This presentation will delve into the rich history and contributions of Dr.B.R. Ambedkar Law College.Dr.B.R. Ambedkar Law College, an integral part of Dr.B.R. Ambedkar Educational Institutions was established in the year 1991, by late G. Venkataswamy Garu, the then Member of Parliament and Deputy Floor leader during 2000-2001. He was also a visionary for a progressive society. It is a moment of pride for our college for having completed glorious 32 years of establishment. Imparting legal education was the most cherished dream of our founder Chairman Late.Sri G. Venkat Swamy Garu.Since its establishment in 1991,Dr. B. R. Ambedkar Law College has been successful in producing legal luminaries, bureaucrats & judges.",
    },
  };

  const noticeBoard = [
    {
      id: 1,
      title: "Notice Board: ",
      description:
        "Dear Students,<br>Warm Greetings! This is a kind request to the reminder regarding your contribution to our annual magazine - articles. Kindly send your esteemed submissions to: <b><a href='mailto:annualmagazinedbralc@gmail.com' style='color:blue; text-decoration:underline;'>annualmagazinedbralc@gmail.com</a><b>",
      logoPath:
        "https://dbraeiassetmanagement.blob.core.windows.net/dbralc/prod/home/Notification_Images/Screenshot (153).png",
    },
    {
      id: 2,
      title: "Notice Board: ",
      description:
        "We are thrilled to announce that the latest India Today rankings have been released, Dr B R Ambedkar Law College is ranked 2nd in all over Telangana. This recognition is a testament to the dedication of our staff, the hard work of our staff and students, and the overall excellence of our academic programs. We are proud to be among the top institutions in the state and remain committed to providing quality education and practical experiences to our students. We wish once again hearty congratulations to all staff members. Thank you from Management.",
      logoPath:
        "https://dbraeiassetmanagement.blob.core.windows.net/dbradc/prod/home/studywithus/law achivements/WhatsApp Image 2024-06-25 at 7.37.51 PM.jpeg",
    },
    {
      id: 3,
      title: "ALUMNI MEET",
      description:"Dear Alumnus / Alumna its time to reminiscence and relive the long lost wonderful days at Dr. B. R. Ambedkar",
      logoPath:
        "https://dbraeiassetmanagement.blob.core.windows.net/dbralc/prod/home/Notification_Images/Notification_Image_law.jpeg",
    },
    {
      id: 4,
      title: "Notice Board: ",
      description: "Spot Admissions for LLM 2024-2025",
      logoPath:
        "https://dbraeiassetmanagement.blob.core.windows.net/dbralc/prod/home/Notification_Images/image_2024_11_13T11_14_15_030Z.png",
    },
  ];

  // const toggleDescription = () => {
  //   setShowFullDescription(!showFullDescription);
  // };

  return (
    <section className="container mt-5">
      <Grid container spacing={5}>
        <Grid item xs={8} md={6} className="p-4">
          <img
            src={Study_with_us_Htext}
            alt="Study with us"
            className="w-100"
          />
          <div className="pt-4">
            <p className="studyDescription my-0">{Study_with.SU.description}</p>
          </div>
        </Grid>

        <Grid
          item
          xs={12}
          md={6}
          style={{ alignItems: "center" }}
          className="noticeBoard"
        >
          <Card className="mb-3" style={{ alignContent: "center" ,backgroundImage: "linear-gradient(180deg, #F15215, #494798)", borderRadius:"10px"}}>
          <NoticeCarosel
            data={noticeBoard.map((notice) => ({
              ...notice,
              description: (
                <span
                  dangerouslySetInnerHTML={{ __html: notice.description }}
                />
              ),
            }))}
          />
          </Card>
        </Grid>
      </Grid>
      <Divider flexItem sx={{
          backgroundImage: "linear-gradient( #F15215, #494798)",
          height: '5px',
          borderRadius: '10px',
          margin: '2px 0',
        }}  />
    </section>
    // </div>
  );
};

export default Study_with_us;
