import React from "react";
import Slider from "react-slick";
import Typography from "@mui/material/Typography";
// import Arrow_Small_Left from "../../Assets/Icons/Arrow_Small_Left.svg";
// import Arrow_Small_Right from "../../Assets/Icons/Arrow_Small_Right.svg";
import "../../Styles/NoticeCarosel.css";

const NoticeCarousel = ({ data }) => {
  // const sliderRef = useRef(null);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 3000,
  };

  return (
    <div className="carousel-container">
      <Typography
        variant="h6"
        className="m-0 carousel-title"
        style={{ color: "white", textAlign: "center" }}
        gutterBottom
      >
        Notice Board:
      </Typography>
      <Slider className="px-3 custom-slider" {...settings}>
        {data.map((item, index) => (
          <div key={index} className="carousel-item">
            <Typography variant="subtitle2" gutterBottom color="white">
              {item.description}
            </Typography>
            <div className="d-flex justify-content-center align-items-center mt-1">
              <img
                src={item.logoPath || ""}
                alt="Logo"
                className="carousel-logo"
                style={{
                  maxWidth: item.id === 1 ? "90%" : "60%",
                  height: item.id === 1 ? "400px" : "auto",
                  borderRadius: "20px",
                  boxShadow: "4px 0px 5px rgba(0, 0, 0, 0.3)" 
                }}
                onError={(e) => {
                  e.target.src =
                    "https://dbraeiassetmanagement.blob.core.windows.net/dbraimt/prod/home/Placeholders/Placeholder_Image.jpg";
                }}
              />
            </div>

            {/* <div className="carousel-navigation">
              <div className="nav-icon" onClick={() => sliderRef.current.slickPrev()}>
                <img src={Arrow_Small_Left} alt="Previous" />
              </div>
              <div className="nav-icon" onClick={() => sliderRef.current.slickNext()}>
                <img src={Arrow_Small_Right} alt="Next" />
              </div>
            </div> */}
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default NoticeCarousel;
